<template>


  <SkBasicForm 
    v-if="userSocialsDataStatus === 'loading'"
  />

  <div v-else-if="userSocialsData?.id">
    

 
    <div class="modal_user_content">

      <div class="image content content_profile">
  
          

        <div class="settings_description">
  

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_fb') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="userSocialsData.link_facebook" placeholder="facebook.com/...">
              <i class="icon facebook"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_yt') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="userSocialsData.link_youtube" placeholder="youtube.com/...">
              <i class="icon facebook"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_ig') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="userSocialsData.link_instagram" placeholder="instagram.com/...">
              <i class="icon facebook"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_tw') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="userSocialsData.link_twitter" placeholder="twitter.com/...">
              <i class="icon twitter"></i>
            </div>
          </div>
  

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_email') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="userSocialsData.link_email" placeholder="Email">
              <i class="icon email"></i>
            </div>
          </div>
  
          <br />
 

          <UiButton
            :name="$t('universal_button_save')"
            :type="'primary'"
            @click="saveSocials"
            :is-loading="isSaving"
            />        

 

        </div>

      </div>

    </div>

    <UserSettingsModalChangePassword 
      :user-id="userSocialsData.id"
      :active="activeModalChangePassword" 
      :old-password="userSocialsData.password_old"
      @close="closeModalPassword"
      />
  

  </div>

    

</template>

<script setup lang="ts">


import type { User } from '@/types/user'
import { isValidUrl } from '@/types/other'

const { t } = useI18n();
const route = useRoute();

const router = useRouter();
const {$api, $popup} = useNuxtApp()

interface Props {
  user: User,
  userId: number,
}

const props = defineProps<Props>();

 

const activeModalChangePassword = ref(false);
const isSaving = ref(false);
const oldNickName = ref('');



const closeModalPassword = function(){
  activeModalChangePassword.value = false;
}

const validate = function(){

  // email validate
  var re = /\S+@\S+\.\S+/;
  if(!re.test(userSocialsData.value.link_email)){
    $popup.error('Incorrect public email');
    return false;
  }

  /* socials */
  if(userSocialsData.value.link_facebook?.length && !isValidUrl(userSocialsData.value.link_facebook,'facebook.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " facebook")
    return false;
  }
  if(userSocialsData.value.link_instagram?.length && !isValidUrl(userSocialsData.value.link_instagram,'instagram.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " instagram")
    return false;
  }
  if(userSocialsData.value.link_youtube?.length && !isValidUrl(userSocialsData.value.link_youtube,'youtube.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " youtube")
    return false;
  }
  if(userSocialsData.value.link_twitter?.length && !isValidUrl(userSocialsData.value.link_twitter,'twitter.com') && !isValidUrl(userSocialsData.value.link_twitter,'x.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " twitter")
    return false;
  }
    
  return true;
}

const saveSocials = function(){

  if(!validate()){
    return;
  }
  
  isSaving.value = true;  
  $api.putGrowerProfile(userSocialsData.value.id, userSocialsData.value)
  .then((res) => {
    isSaving.value = false;
    $popup.success(t('profile_menu_edit_profile_message_save_profile'));
    useAuth().refresh();
  })
  .catch(function (error) {
    isSaving.value = false;
  });

}


const loadData = async function(id: number) {   
  const response = await $api.getGrowerSettings(id, 'socials')  
  oldNickName.value = response.name;
  return response;  
}


const { status: userSocialsDataStatus,  data: userSocialsData } = await useLazyAsyncData('userSocialsData', async () => await loadData(props.userId))



</script>

<style scoped>

.content_profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.modal_user_content .settings_description {
    display: inline-block;
    width: calc(100% - 230px);
    vertical-align: middle;
    margin-right: auto;
}

.modal_user_content .settings_avatar {
    display: inline-block;
    vertical-align: top;
    width: 180px;
    margin-left: 30px;
    position: relative;
}
 
.modal_user_content .ava{
  width: 180px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.modal_user_content .ava .img{
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 10px;
    border-radius: 100%;
}


.modal_user_content .btn_black {
    background-color: #1B1C1D;
    color: #FFFFFF;
    text-shadow: none;
}

.modal_user_content .save_profile_btn{
    background-color: #21BA45;
    color: #FFFFFF;
    text-shadow: none;
}

.modal_user_content .settings_description .row{
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.modal_user_content .settings_description .label{
    display: inline-block;
    min-width: 280px;
}

.modal_user_content .settings_description .field_input{
    display: inline-block;
    width: 100%;
}

.modal_user_content .settings_description .field_input input{
    width: 100%;
}



@container pb (max-width: 768px) {

  .modal_user_content .ava{
    justify-content: center;
    margin: 0 auto;
  }
  .content_profile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .modal_user_content .settings_avatar{

      margin-left: auto;
      margin-right: auto;
  }

  .modal_user_content .settings_description{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 20px;
  }

  .modal_user_content .settings_description .row{
    display: flex;
    flex-direction: column;
  }

}


</style>
